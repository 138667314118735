import { HolidayResultsTheme } from '@supersonic/config';

export const holidayResultsTheme: HolidayResultsTheme = {
  dividerColor: 'grey2',
  compareMoreRowColor: 'sand',
  linkColor: 'secondary',
  linkHoverColor: 'secondary_darker1',
  labelTextColor: 'grey6',
  priceTextColor: 'primary',
  uspBackgroundColor: 'supporting1_lighter',
  uspIconColor: 'supporting1',
  outboundInboundLabelColor: 'secondary_darker1',
  flightInfoItemBackgroundColor: 'secondary_lighter4',
  flightInfoItemTextColor: 'black',
  boardBasisBackgroundColor: 'sand',
  yourSelectedPropertyBackgroundColor: 'secondary',
};
